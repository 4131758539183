import React, {useState, useEffect, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Portfolio.module.scss'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'
import { CardImage } from '../CardImage/CardImage'
import { arrPortfolio } from '../../utils/data'
import image_7 from '../../image/Advantages/wheel.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const Portfolio = ({isImageOpen}) => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)


    useEffect(() => {
        const getCards = () => {
            if (width >= 980) {
                setMoviesTotal(6);
                setAddMovies(3);
                } else if (width < 980 && width >= 500) {
                    setMoviesTotal(6);
                    setAddMovies(2);
                        } else if (width <= 500) {
                            setMoviesTotal(4);
                            setAddMovies(1);
                            }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Немного фото <span> нашей работы</span></motion.h2>
                <motion.div className={styles.box_line} variants={boxAnimation}>
                                <div className={styles.line} ></div>
                                    <img className={styles.wheel} src={image_7} alt='колесо'/>
                                <div className={styles.line}></div>
                            </motion.div>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    {arrPortfolio.map((item, index) =>{
                        const keyUid = uuidv4()
                        if (index + 1 <= moviesTotal) {
                            return(
                                <CardImage
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen}
                                    arr={arrPortfolio} 
                                />
                            ) 
                        }else {
                            return ''
                        }
                    })}
                </motion.ul>
                {moviesTotal < arrPortfolio.length && <button  className={styles.button} onClick={addCards}>Ещё работы</button>}
            </div>
        </motion.section>
    )
}