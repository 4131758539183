import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/InitialPage/label.png'
import icon_2 from '../../image/InitialPage/time.png'
import icon_3 from '../../image/InitialPage/shield.png'
import icon_4 from '../../image/InitialPage/watch.png'

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>Эвакуатор по Москве и Московской области</motion.h1>
                            <motion.div className={styles.box} variants={subtitleAnimation}>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_1} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Низкая стоимость</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_2} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Быстрая подача</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_3} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Полная безопасность</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <img className={styles.image} src={icon_4} alt='иконка'/>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Работаем 24/7</p>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}