import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'
import image_7 from '../../image/Advantages/wheel.svg'



export const Feedback = () => {


    return (
        <section className={styles.main} id='Septic'>
            <div className={styles.box}>
                <h2 className={styles.title}>Отзывы <span>наших клиентов</span></h2>
                <div className={styles.box_line}>
                    <div className={styles.line} ></div>
                        <img className={styles.wheel} src={image_7} alt='колесо'/>
                    <div className={styles.line}></div>
                </div>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Аня</p>
                                        <p className={styles.comment}>26.08.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Все четко, без подвохов. Это был мой первый опыт с эвакуатором. Написала, договорились на удобное время, сразу посчитали, сколько будет стоить. Приятно иметь дело, видно, что ребята настроены помочь и оказать сервис. Это ценно. Большое спасибо!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Дмитрий Коростелёв</p>
                                        <p className={styles.comment}>24.07.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Всем доброго времени суток! На днях мой «чудо конь» сломался пришлось искать эвакуатор 📣 Позвонил мне вежливо ответили , проконсультировали и договорились 🤝 Время ожидания было не долгим если взять во внимание , что вызвал я в пятницу вечером 😅 Новенькая , чистенькая , ухоженная Газелька 👍 Отдельная благодарность водителю Максиму Побыстрому загрузил «моего коня» и довез до сервиса )) Вообщем отличные ребята , рекомендую !</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>Денис</p>
                                    <p className={styles.comment}>15.06.2023</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Договорились с Дмитрием на определенное время. Приехал точно к указонному времени. Все четко по делу погрузили авто. Приятно ехать с человеком который знает свою работу и относится с полной отдачей. Автомобиль новенький все чистенько ехать приятно! Всем советую 👍💪</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Alex</p>
                                        <p className={styles.comment}>02.06.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Созвонился в 7 вечера с Дмитрием, задача была оперативно отправить машину в СПб. В 22:30 уже грузили вместе на эвак, к полудню следующего дняуже была в Питере. Если хотите нестандартный гибкий и комфортный подход, то вам именно сюда</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Камиль</p>
                                        <p className={styles.comment}>28.05.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Спасибо за помощь! Дмитрий пришел на выручку, был вежлив, дружелюбен и пунктуален. При заключении сделки попросил меня скинуть условную предоплату. Сначала меня это немного насторожило, но потом оценив ситуацию и приняв во внимание ночное время, я все-таки перевел предолату. и ни разу не пожалел об этом. Дмитрий был возле моего авто в точности до минуты. Во время томительного ожидания от него ругулярно приходили сообщения о его местоположении и о том, сколько еще ему осталось до места. В общем подводя итог, если вы попали с непростую ситуацию и без эвакуатора никак, то смело выбырайте этого человека. Рекомендую!!!</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Алексей Дудко</p>
                                        <p className={styles.comment}>19.05.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Отличный сервис! Написал поздно вечером, ответили в течении минуты, договорились на утро, так как срочности уже небыло, моя машина стала мертвым грузом. Утром в течении часа приехали, погрузили мою ласточку и привезли в сервис. Качество на уровне, цена очень приятная. Спасибо!</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Александра</p>
                                        <p className={styles.comment}>21.04.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Стали на обочине по нужде и все, машина не заводиться (старенький аккумулятор давно пора было менять), пробовали толкать-дохлый номер. Вызвали эвакуатор, который быстро приехал, не дал нам замерзнуть. Пока мы в кабине размещались (нас 5 человек и мы все свободно поместились), он быстренько машину загрузил. Всех довез в целости и сохранности. Все остались довольны!👍</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}