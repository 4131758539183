import React from 'react'
import styles from './Practical.module.scss'
import icon_1 from '../../image/Practical/img_1.jpg'
import icon_2 from '../../image/Practical/img_2.jpg'
import icon_3 from '../../image/Practical/img_5.jpg'
import { SliderAdvantages } from '../../components/SliderAdvantages/SliderAdvantages'

export const Practical = () => {

    return (
        <section className={styles.main}>
            <div className={styles.container}>
                <div className={styles.box}>
                    <h2 className={styles.title} >ОПЕРАТИВНО. КАЧЕСТВЕННО. БЕЗОПАСНО.</h2>
                    <div className={styles.box_image}>
                        <img className={styles.image} src={icon_1} alt='эвакуатор'/>
                        <img className={styles.image} src={icon_2} alt='эвакуатор'/>
                        <img className={styles.image} src={icon_3} alt='эвакуатор'/>
                    </div>
                    <h2 className={styles.title} >ЗВОНИТЕ СЕЙЧАС, ПРИЕДЕМ БЫСТРО!</h2>
                </div>
                <SliderAdvantages/>
            </div>
        </section>
        
        
    )
}

