import React from 'react'
import styles from './Deadlines.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/img_4.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_6 from '../../image/Deadlines/img_6.svg'
import image_7 from '../../image/Advantages/wheel.svg'

export const Deadlines = () => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .9}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Deadlines' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.15, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Порядок<span> оформления заказа</span></motion.h2>
            <motion.div className={styles.box_line} variants={boxAnimation}>
                <div className={styles.line} ></div>
                    <img className={styles.wheel} src={image_7} alt='колесо'/>
                <div className={styles.line}></div>
            </motion.div>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы отправляете нам заявку на эвакуацию транспорта в мессенджере или звоните нам по телефону</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Наш менеджер консультирует вас, сообщает стоимость  перевозки, время ожидания и условия оплаты</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Погрузка</h3>
                            <p className={styles.item_text}>Водитель поверяет документы на собственность и производит погрузку транспортного средства на эвакуатор</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Перевозка</h3>
                            <p className={styles.item_text}>Мы бережно перевозим ваше транспортное средство в оговоренную точку назначения</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Получение</h3>
                            <p className={styles.item_text}>Вы или ваш представитель получаете транспортное средство в пункте назначения и осматриваете его</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>После осмотра транспортного средства вы оплачиваете услуги эвакуации любым удобным для вас способом</p>
                        </li>
                    </motion.ul>
                    <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79258014277' aria-label='связаться вацап'>вызвать эвакуатор</a>
            </div>   
        </motion.section>
        
        
    )
}
