import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О нашей <span>компании</span></h2>
                        <p className={styles.text}>&#8195;Наша компания оказывает профессиональные услуги эвакуации в Москве и всех районах Московской области уже более десяти лет. Благодаря собственному парку техники и грамотно организованному дежурству наши тарифы на 15-20% ниже средних, а время подачи составляет всего от 20 до 30 минут. Вы можете вызвать наш эвакуатор 24 часа в сутки и любой день недели.</p>
                        <p className={styles.text}>&#8195;Мы перевозим легковые автомобили с любым дорожным просветом, внедорожники, мото и спецтехнику, грузовые машины, автобусы и другую технику. Быстро эвакуируем как новые, так и неисправные транспортные средства, вне зависимости от характера и степени поломки. Принимаем заказы как от юридических лиц, так и от частных клиентов.</p>
                        <p className={styles.text}>&#8195;Основными принципами нашей деятельности является: надёжность и стабильность в работе с нашими клиентами, ответственный подход наших сотрудников к выполнению своей работы.</p>
                        <p className={styles.text}>&#8195;Мы всегда готовы оперативно прийти Вам на помощь и профессионально выполнить свою работу!</p>
                    </motion.div>
            </div>
        </motion.section>
        
        
    )
}