import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"
import image_7 from '../../image/Advantages/wheel.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.2, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.div className={styles.box_line} variants={boxAnimation}>
                                <div className={styles.line} ></div>
                                    <img className={styles.wheel} src={image_7} alt='колесо'/>
                                <div className={styles.line}></div>
                            </motion.div>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Собственный автопарк</h3>
                        <p className={styles.item_text}> Большой парк собственных современных эвакуаторов, которые всегда готовы прийти Вам на помощь раньше других.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Быстрая подача</h3>
                        <p className={styles.item_text}>Благодаря собственному парку техники и грамотно организованному дежурству время подачи составляет всего 20-30 минут.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Демократичные цены</h3>
                        <p className={styles.item_text}>Лучшее предложение цены и качества представляемых услуг. Обращаясь к нам напрямую, Вы получаете самую низкую цену, избегая услуг посредников.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Профессионализм</h3>
                        <p className={styles.item_text}>Профессиональные менеджеры помогут Вам правильно подобрать необходимый эвакуатор и проконсультируют по любому вопросу касающемуся эвакуации.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>05</p>
                        <h3 className={styles.item_title}>Удобный расчет</h3>
                        <p className={styles.item_text}>Работаем за наличные и безналичный расчет. Заключаем договора с компаниями на разовые и постоянные услуги. Выдаем чеки для страховых компаний.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>06</p>
                        <h3 className={styles.item_title}>Огромный опыт</h3>
                        <p className={styles.item_text}>Мы не компания однодневка, завтра и через год наш контактный номер не будет «не в зоне доступа» или выключен. Работаем с 2011 года и помогаем в ситуации любой сложности.</p>
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}