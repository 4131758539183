import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Questions } from '../../components/Questions/Questions'
import {Advantages} from '../../components/Advantages/Advantages'
import { Practical } from '../../components/Practical/Practical'
import {Services} from "../../components/Services/Services"
import {Deadlines} from "../../components/Deadlines/Deadlines"
import {Feedback} from '../../components/Feedback/Feedback'
import { Portfolio } from '../../components/Portfolio/Portfolio'

export const Main = ({isOpen, popupOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
            />
            <SliderAdvantages/>
            <AboutUs
            />
            <Services
            />
            <Advantages
            />
            <Feedback/>
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Deadlines
            />
            <Questions/>
            <Practical/>
        </section>
    )
}